import { MatChipInputEvent } from '@angular/material';

import { FuseUtils } from '@fuse/utils';

export class Booking {
    _id: string;
    reference: string;
    customer: any;
    status: any[];
    payments: any[];
    hotels: any[];
    agent:any;
    bookingCreatedAt:any;
 
    /**
     * Constructor
     *
     * @param booking
     */
    constructor(booking?) {
        booking = booking || {};
        this._id = booking._id || null;
        this.reference = booking.bkref || 'BC -';
        this.customer = booking.customer || null;
        this.status = booking.status || [];
        this.payments = booking.payments || [];
        this.hotels=booking.hotels||[];
        this.agent=booking.agent||null;
     this.bookingCreatedAt=   booking.bookingCreatedAt||null;
      
        

    }

}
