import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MaterialModule } from '../material.module';
import { BookingsComponent } from './bookings.component';
import { BookingsService } from './bookings-service';
import { BookingComponent } from './booking/booking.component';
import { CustomersService } from '../customers/customers-service';
import { BookingProductsComponent } from './booking-products/booking-products.component';
import { BookingHotelProductComponent } from './booking-products/booking-hotel-product/booking-hotel-product.component';
import { HotelsService } from '../hotels/hotels-list/hotels-service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from 'app/moment-utc-date-adapter';
import { SettingsService } from '../global-site/settings.service';
import { AdminsService } from '../dashboard/admins/admins-service';


const routes: Routes = [
    {
        path: 'list',
        component: BookingsComponent,
        resolve: {
            data: BookingsService,
        }
    },
    {
        path: 'single/:id',
        component: BookingComponent,
        resolve: {
            data: BookingsService, 
            settings: SettingsService,
            customers: CustomersService
        }
    },
];

@NgModule({
    declarations: [
        BookingsComponent,
        BookingComponent,
        BookingProductsComponent,
        BookingHotelProductComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxChartsModule,
        FuseSharedModule,
        FuseWidgetModule,
        MaterialModule
    ],
    providers: [
        SettingsService,
        BookingsService,
          AdminsService,
        CustomersService,
        HotelsService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-CA' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {provide: LOCALE_ID, useValue: "fr-CA" },

    ],
    entryComponents: [
        BookingHotelProductComponent
    ]
})
export class BookingsModule {
}
