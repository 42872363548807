import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { B2BService } from '../b2b.service';
import { FuseUtils } from '@fuse/utils';

import { fuseAnimations } from '@fuse/animations';
import { Globals } from 'app/global/global';
import { DataSource } from '@angular/cdk/collections';
import { MatDialog, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
  import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProCostomerFormComponent } from '../pro-costomer-form/pro-costomer-form.component';
import { FormGroup } from '@angular/forms';
import { DialogConfirm } from 'app/main/shared/dialog-confirm-component';
@Component({
  selector: 'app-list-partenaire',
  templateUrl: './list-partenaire.component.html',
  styleUrls: ['./list-partenaire.component.scss'],
  animations : fuseAnimations
})
export class ListPartenaireComponent implements OnInit {
 
  _env = Globals

  dataSource: any | null;
  displayedColumns = [  'firstName', 'lastName', 'business_name', 'phone', 'identifier', 'status','action'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('filter')
  filter: ElementRef;
  dialogRef: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _b2bService: B2BService,
    private _matDialog: MatDialog,
    public dialog: MatDialog,
    private _matSnackBar: MatSnackBar
  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  ngOnInit() {
    this._b2bService.onPartenairesChanged.subscribe((data) => {
      // Update your dataSource or perform any necessary actions
      this.dataSource = new FilesDataSource(this._b2bService, this.paginator, this.sort);
    });

    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }

        this.dataSource.filter = this.filter.nativeElement.value;
      });

  }


  newBooking() {
    //this._bookingsService.setUrl("/create")
    /*this._bookingsService.create({})
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res)
          this.router.navigate(['bookings/single/' + res.data.bookingId]);

      });
*/
  }


  /**
 * On destroy
 */
ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.next();
  this._unsubscribeAll.complete();
}



 //newPromo
 newPro() {
  this.dialogRef = this._matDialog.open(ProCostomerFormComponent, {
    panelClass: 'promo-form-dialog',
    data: {
      action: 'new',
    }
  });
  this.dialogRef.afterClosed()
    .subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }
      if (response[0] == "ADD") {
        this.createPro(response[1].getRawValue());
      }
    });
}

openPro(pro, action) {
  this.dialogRef = this._matDialog.open(ProCostomerFormComponent, {
    panelClass: 'promo-form-dialog',
    data: {
      action: "edit",
      pro: pro,
    }
  });
  this.dialogRef.afterClosed()
    .subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      if (response[0] == "SAVE") {
        this.savePro(response[1].getRawValue());
      }
    });

}

createPro(data) {
    //Add pro customer account
  this._b2bService.setUrl("/create")
  this._b2bService.create(data)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
       this._b2bService.getPartenaires();
       this._matSnackBar.open("Ajouter avec success", 'OK', {
        verticalPosition: 'top',
        horizontalPosition:'center',
        duration: 2000
      });
    },(error=>{

      console.log();
      
    
       
        this._matSnackBar.open(error.message, 'OK', {
          verticalPosition: 'top',
          horizontalPosition:'center',
          duration: 10000
        });
      
    }));
}

savePro(data) {
  //Update pro customer account
  this._b2bService.setUrl("/update")
  this._b2bService.create(data)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
       this._b2bService.getPartenaires();
       this._matSnackBar.open("Modifier avec success", 'OK', {
        verticalPosition: 'top',
        horizontalPosition:'center',
        duration: 2000
      });
      
    });
}


openDialogForDeletePro(id) {
  const dialogRef = this.dialog.open(DialogConfirm, {
    data: {
      confirm: true,
      dialogTitle: "Désactiver Compte Partenaire",
      dialogMessage: "Voulez vous vraiment désactive compte de cette partenaire ?",
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result == true) {
      this.deletePro(id);

    }
  });
}


deletePro(pro_id) {
  //Delete hotel promo
  this._b2bService.setUrl("/disactive")
  this._b2bService.delete(pro_id)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {
      this._matSnackBar.open("Désactiver avec success", 'OK', {
        verticalPosition: 'top',
        horizontalPosition:'center',
        duration: 2000
      });
      this._b2bService.getPartenaires()
    });
}
}

export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');
  

 
  constructor(
    private _b2bService: B2BService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();
console.log(this._b2bService.partenaires);

    this.filteredData = this._b2bService.partenaires;
    
    console.log(this.filterData);
    
 
    

   }



  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
        this._b2bService.onPartenairesChanged,
        this._matPaginator.page,
        this._filterChange,
        this._matSort.sortChange
    ];

    return merge(...displayDataChanges).pipe(
        map(() => {
            let data = this._b2bService.partenaires.slice();
            console.log('Raw Data:', data);

            data = this.filterData(data);
            console.log('Filtered Data:', data);

            this.filteredData = [...data];

            data = this.sortData(data);

            // Grab the page's slice of data.
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.slice(startIndex, startIndex + this._matPaginator.pageSize);
        })
    );
}

  
  

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData() {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    console.log(filter);
    
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data) {
    console.log("data",data);
    
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data) {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._matSort.active) {
        case 'firstName':
          [propertyA, propertyB] = [a.reference, b.reference];
          break;
        case 'lastName':
          [propertyA, propertyB] = [a.products, b.products];
          break;
        case 'phone':
          [propertyA, propertyB] = [a.customer.fullName, b.customer.fullName];
          break;
        
        case 'status':
          [propertyA, propertyB] = [a.status[0].name, b.status[0].name];
          break;
       
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {
  }


  
}
