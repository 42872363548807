export const paymentMethods = [
    {
        'id': 1,
        'name': 'Virement Bancaire',
    },
    {
        'id': 2,
        'name': 'Espèces',
    },
    {
        'id': 3,
        'name': 'Carte Bancaire',
    },
    {
        'id': 4,
        'name': 'Carte E-dinar',
    },
];
