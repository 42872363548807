import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Http } from '@angular/http';
import { Globals } from 'app/global/global';

@Injectable()
export class AuthService extends DataService {


  constructor(http: Http) {
    super(Globals.ServerPoint, 'api/auth/admin', http);
  }
}
