import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject, Component } from "@angular/core";


export interface DialogData {
    confirm: boolean;
    dialogTitle: string;
    dialogMessage: string;
  }
  
@Component({
  selector: 'dialog-confirm',
  templateUrl: './dialog-confirm.html',
})
export class DialogConfirm {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}