import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    // Private
    private _unsubscribeAll: Subject<any>;

    invalidLogin: boolean = false;
    errorLogin: boolean = false;
    errorLoginMessage: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseProgressBarService: FuseProgressBarService,
        public router: Router,
        public auth: AuthService

    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._unsubscribeAll = new Subject();

        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                // if you need to scroll back to top, here is the right place
                window.scrollTo(0, 0);
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.checkLogin();

        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    // UserSignIn

    signIn(credentials) {
        this.invalidLogin = false;
        this._fuseProgressBarService.show();
        this.auth.login(credentials)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {

                this._fuseProgressBarService.hide();
                this.errorLogin = false;
                if (result) {

                    this.checkLogin()
                }

                else
                    this.invalidLogin = true;
            },
                err => {

                    this._fuseProgressBarService.hide();

                    const msg = JSON.parse(err._body).message
                    this.invalidLogin = true;
                    this.errorLogin = true;
                    this.errorLoginMessage = msg;

                }
            )
    }


    private checkLogin() {
        if (this.auth.isLoggedFn())
            this.router.navigate(['/']);
    }



  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
