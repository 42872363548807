import { Component, OnInit, Input } from '@angular/core';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'custom-dropzone-image-preview',
  templateUrl: './custom-dropzone-image-preview.component.html',
  styleUrls: ['./custom-dropzone-image-preview.component.scss']
})
export class CustomDropzoneImagePreviewComponent extends NgxDropzonePreviewComponent implements OnInit {
  

  @Input()
  url:string;

  imageSrc = "";

 
  constructor(
    sanitizer: DomSanitizer
  ) {
    super(sanitizer);

 
  }

  ngOnInit() {

    this.imageSrc = 'https://api.forsavoyages.com/' + this.url;

    if (!this.file) {
      console.error('No file to read. Please provide a file using the [file] Input property.');
      return;
    }

  }


 
}
