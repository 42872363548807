import { BadInput } from '../../app/common/bad-input';
import { NotFoundError } from './../common/not-found-error';
import { AppError } from './../common/app-error';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/throw';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { JwtHelperService } from '@auth0/angular-jwt';



@Injectable()
export class DataService {

    isLogged: boolean = false;
    currentAdmin: any;
    config: any;
    private url;
    private endpoint;

    constructor(private endPoint: string, private routeService: string, private http: Http) {

        this.endpoint = endPoint;
        this.url = endPoint + routeService;
        this.config = {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }

        let token = localStorage.getItem('token');
        if (token) {
           
            
            const jwt = new JwtHelperService();
            this.currentAdmin = jwt.decodeToken(token);
            //console.log(jwt.decodeToken(token));
            
            //console.log(this.currentAdmin);
            
            this.isLogged = true;
        }

    }

    isLoggedFn() {
        return this.isLogged
    }

    setUrl(segment) {
        this.url = this.endPoint + this.routeService + segment;
    }
    getUrl() {
        return this.endPoint + this.routeService;
    }

    getEndPoint() {
        return this.endpoint;
    }

    login(resource) {
        return this.http.post(this.url, JSON.stringify(resource), this.config)
            .map(response => {
                console.log('response:::' + response);
                let result = response.json();
                console.log('result:::' + JSON.stringify(result));
                if (result) {
                    localStorage.setItem('token', result.result.token);
                    let jwt = new JwtHelperService();
                    this.currentAdmin = jwt.decodeToken(localStorage.getItem('token'));
                    this.isLogged = true;
                    return true;
                }
                else return false;
            })
        //.catch(this.handleError);
    }

    logout() {
        localStorage.removeItem('token');
        this.isLogged = false;
        this.currentAdmin = null;
    }


    get getCurrentAdmin() {
        let token = localStorage.getItem('token');
        if (!token) return null;

        return new JwtHelperService().decodeToken(token);

    }

    getAll() {
        let options = this.requestOptions()
        return this.http.get(this.url, options)
            .map(
                response => {

                    return response.json()

                }
            )
        //.catch(this.handleError);
    }
    get(resource) {
        let options = this.requestOptions();
        options.body = resource;
        return this.http.get(this.url, options)
            .map(
                response => {

                    return response.json()

                }
            )
        //.catch(this.handleError);
    }

    auth_getAll() {
        let options = this.requestOptions()
        return this.http.get(this.url, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }

    create(resource) {

        let options = this.requestOptions();
        return this.http.post(this.url, resource, options)
            .map(response => response.json())
            .catch(
                (error: Response) =>
                    Observable.throw(error.json())
            );
    }


    update(resource) {
console.log(this.url);
console.log(resource);


        let options = this.requestOptions();

        return this.http.put(this.url, resource, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }

    delete(id) {
        let options = this.requestOptions();
        return this.http.delete(this.url + '/' + id, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }


    postUpload(uploadData) {
        let options = this.requestOptions();
        return this.http.post(this.url, uploadData, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }
    putUpload(uploadData) {
        let options = this.requestOptions();
        return this.http.put(this.url, uploadData, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }


    upload(selectedFile) {
        const uploadData = new FormData();
        uploadData.append('image', selectedFile, selectedFile.name);

        let options = this.requestOptions();
        return this.http.post(this.url, uploadData, options)
            .map(response => response.json())
        //.catch(this.handleError);
    }

    getUploadHeaders() {
        let token = localStorage.getItem('token');
        let obj = { 'x-auth-token': token }
        return obj;
    }

    private requestOptions(): RequestOptions {
        let headers = new Headers();
        let token = localStorage.getItem('token');
        headers.append('x-auth-token', token);
        let options = new RequestOptions({ headers: headers });
        return options;
    }

}