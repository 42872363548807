import { MatChipInputEvent } from '@angular/material';

import { FuseUtils } from '@fuse/utils';

export class SiteSetting {
    _id: string;
    metaInfo: any;
    workingTimes: string;
    mapFrame: string;
    socialNetworks: any;
    address:[string]
    phones:[string]
    emails:[string]
    faxs:[string]
    copyright: string
    mailServer: any


    /**
     * Constructor
     *
     * @param SiteSetting
     */
    constructor(siteSetting?) {

        this._id = siteSetting._id || null;
        this.metaInfo = siteSetting.metaInfo || { title: '', keywords: '', description: '' }
        this.workingTimes = siteSetting.workingTimes || '';
        this.mapFrame = siteSetting.mapFrame || '';
        this.socialNetworks = siteSetting.socialNetworks || { facebook: '', instagram: '' };
        this.address = siteSetting.address || [''];
        this.phones = siteSetting.phones || [''];
        this.emails = siteSetting.emails || [''];
        this.faxs = siteSetting.fax || [''];
        this.copyright = siteSetting.copyright || '';

        this.mailServer = siteSetting.mailServer || {
            emailFrom: '',
            hostname: '',
            username: '',
            password: '',
            port: 0,
            default: '',
            secure: '',
            emailHeader: '',
            emailFooter: '',
        };
    }

}
