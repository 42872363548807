import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

    {
        id: 'global',
        title: 'Global',
        type: 'group',
        children: [
            {
                id: 'global-module',
                title: 'Global',
                type: 'collapsable',
                icon: 'language',
                children: [
                    {
                        id: 'settings_website',
                        title: 'Site Paramètres',
                        type: 'item',
                        url: '/settings/global'
                    },
                    {
                        id: 'cms',
                        title: 'CMS',
                        type: 'item',
                        url: '/cms/site'
                    },
                    {
                        id: 'locations',
                        title: 'Destinations',
                        type: 'item',
                        url: '/locations/list'
                    }
                ]
            },
        ]
    },

    {
        id: 'applications',
        title: 'Agence',
        type: 'group',
        children: [
            {
                id: 'hotels-module',
                title: 'Hôtels',
                type: 'collapsable',
                icon: 'local_hotel',
                children: [
                    {
                        id: 'hotels-list',
                        title: 'Hôtels Tunisie',
                        type: 'item',
                        url: '/hotels/list'
                    },
                    {
                        id: 'settings',
                        title: 'Paramètres',
                        type: 'item',
                        url: '/hotels/settings'
                    }
                ]
            },
            {
                id: 'guesthouses-module',
                title: "Maisons d'Hôtes",
                type: 'collapsable',
                icon: 'store',
                children: [
                    {
                        id: 'guesthouses-list',
                        title: "Maisons d'Hôtes",
                        type: 'item',
                        url: '/guesthouses/list'
                    },
                ]
            },
            {
                id: 'excursions-module',
                title: 'Excursions',
                type: 'collapsable',
                icon: 'airport_shuttle',
                children: [
                    {
                        id: 'excursions-list',
                        title: 'Excursions',
                        type: 'item',
                        url: '/excursions/list'
                    },
                ]
            },
            {
                id: 'tours',
                title: 'Tours',
                type: 'collapsable',
                icon: 'local_airport',
                children: [
                    {
                        id: 'list_tours',
                        title: 'Tours',
                        type: 'item',
                        url: '/tours'
                    },
                    {
                        id: 'settings_tours',
                        title: 'Paramètres',
                        type: 'item',
                        url: '/tours/settings'
                    }
                ]
            },
            {
                id: 'bookings-list',
                title: 'Réservations',
                type: 'item',
                icon: 'card_membership',
                url: '/bookings/list'
            },
        ]
    },


    {
        id: 'b2b-list',
        title: 'Espace B2B',
        type: 'group',
        children: [
            {
                id: 'listPartenaire',
                title: 'Partenaire',
                type: 'item',
                icon: 'home',
                url: '/b2b/list-partenaire'
            },
            {
                id: 'listBookingss',
                title: 'Réservations',
                type: 'item',
                icon: 'card_membership',
                url: '/b2b/réservations'
            },
            
        ]
    },

    {
        id: 'g_compta',
        title: 'Compabilité',
        type: 'group',
        children: [
            {
                id: 'invoices',
                title: 'Factures',
                type: 'item',
                icon: 'assignment',
                url: '/invoices'
            },
        ]
    }
];
