import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Http } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'app/services/data.service';
import { Globals } from 'app/global/global';

@Injectable()
export class CustomersService extends DataService implements Resolve<any>
{
    customers: any[];
    onCustomersChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: Http
    ) {
        super(Globals.ServerPoint, 'api/customers', _httpClient);
        // Set the defaults
        this.onCustomersChanged = new BehaviorSubject({});
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCustomers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }



    getCustomers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getAll()
                .subscribe((response: any) => {
                    this.customers = response.result;
                    this.onCustomersChanged.next(this.customers);
                    resolve(response.result);
                }, reject);
        });
    }
}
