import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Http } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'app/services/data.service';
import { Globals } from 'app/global/global';

@Injectable()
export class BookingsService extends DataService implements Resolve<any>
{
    routeParams: any;

    bookings: any[];
    onBookingsChanged: BehaviorSubject<any>;
    onSingleBookingChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {Http} _httpClient
     */
    constructor(
        private _httpClient: Http
    ) {
        super(Globals.ServerPoint, 'api/bookings', _httpClient);
        //super(Globals.ServerPoint, 'api/bookings', _httpClient);
        // Set the defaults
        this.onBookingsChanged = new BehaviorSubject({});
        this.onSingleBookingChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            if (this.routeParams.id != null)
                Promise.all([
                    this.getSingleBooking()
                ]).then(
                    () => {
                        resolve();
                    },
                    reject
                );
            else
                Promise.all([
                    this.getBookings()
                ]).then(
                    () => {
                        resolve();
                    },
                    reject
                );

        });
    }

    /**
     * Get bookings
     *
     * @returns {Promise<any>}
     */
    getBookings(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.setUrl("")
            this.getAll()
                .subscribe((response: any) => {
                    this.bookings = response.result;
                    this.onBookingsChanged.next(this.bookings);
                    resolve(response.result);
                }, reject);
        });
    }

    /**
     * Get single booking
     *
     * @returns {Promise<any>}
     */
    getSingleBooking(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.id === 'new') {
                this.onSingleBookingChanged.next(false);
                resolve(false);
            } else {

                this.setUrl("/single/" + this.routeParams.id) 
                this.getAll()
                    .subscribe((response: any) => {
                        this.onSingleBookingChanged.next(response.result);
                        resolve(response.result);
                    }, reject);

            }
        });
    }
}
