import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatCheckboxModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { CKEditorModule } from 'ckeditor4-angular'
import { QuillModule } from 'ngx-quill'
;
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { LoginModule } from './main/authentication/login/login.module';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { Http, HttpModule } from '@angular/http';
import { AuthService } from './services/auth.service';
import { DatePipe } from '@angular/common';
import { FacilitiesModule } from './main/facilities/facilities.module';
import { MaterialModule } from './main/material.module';
import { DialogConfirm } from './main/shared/dialog-confirm-component';
import { PaymentsModule } from './main/payments/payments.module';
import { BookingsModule } from './main/bookings/bookings.module';
import { UploadService } from './services/upload.service';
import { AuthGuard } from './services/auth-guard.service';
 
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MomentUtcDateAdapter } from './moment-utc-date-adapter';
import { CmsModule } from './main/cms-site/cms.module';
import { HotelsModule } from './main/hotels/hotels.module';
import { B2BModule } from './main/b2b/b2b.module';

registerLocaleData(localeFr);

export function jwtOptionsFactory(tokenService) {
    return {
        tokenGetter: () => {
            return tokenService.getAsyncToken();
        }
    }
}

const appRoutes: Routes = [

    {
        path: 'auth',
        loadChildren: './main/authentication/login/login.module#LoginModule',
    },
    {
        path: 'dashboard',
        loadChildren: './main/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'locations',
        loadChildren: './main/locations/locations.module#LocationsModule',
        canActivate: [AuthGuard] 
    },
    {
        path: 'hotels',
        loadChildren: './main/hotels/hotels.module#HotelsModule',
        canActivate: [AuthGuard] 
    },
    {
        path: 'guesthouses',
        loadChildren: './main/guesthouses/guesthouses.module#GuesthousesModule',
        canActivate: [AuthGuard] 
    },
    {
        path: 'excursions',
        loadChildren: './main/excursions/excursions.module#ExcursionsModule',
        canActivate: [AuthGuard] 
    },
    {
        path: 'bookings',
        loadChildren: './main/bookings/bookings.module#BookingsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: './main/global-site/global-site.module#GlobalSiteModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'cms',
        loadChildren: './main/cms-site/cms.module#CmsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'b2b',
        loadChildren: './main/b2b/b2b.module#B2BModule',
        canActivate: [AuthGuard] 
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        AppComponent,
        DialogConfirm,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        QuillModule.forRoot(),


        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', useHash: true }),

        TranslateModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Http]
            }
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MaterialModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        FroalaEditorModule.forRoot(), 
        FroalaViewModule.forRoot(),

        // App modules
        LayoutModule,
        SampleModule,
        LoginModule,
        FacilitiesModule,
        PaymentsModule,
        BookingsModule,
        B2BModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-CA' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {provide: LOCALE_ID, useValue: "fr-CA" },
        DatePipe,
        AuthService,
        UploadService,
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        DialogConfirm,
    ],

})
export class AppModule {
}
