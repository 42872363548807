import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    MatButtonModule, MatChipsModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatRippleModule, MatSelectModule, MatSnackBarModule, MatSortModule,
    MatTableModule, MatTabsModule, MatAutocompleteModule
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CKEditorModule } from 'ckeditor4-angular';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { AmazingTimePickerService, AmazingTimePickerModule } from 'amazing-time-picker';
 
import { MaterialModule } from '../material.module';
import { RoomsListComponent } from '../rooms/rooms-list/rooms-list.component';
import { RoomComponent } from '../rooms/room/room.component';
import { RoomPricesComponent } from '../rooms/room-prices/room-prices.component';
import { RoomFacilitiesComponent } from '../rooms/room-facilities/room-facilities.component';
 import { RoomsModule } from '../rooms/rooms.module';
import { RoomPricingComponent } from '../rooms/room-pricing-periods/room-pricing/room-pricing.component';
import { RoomPricingPeriodsComponent } from '../rooms/room-pricing-periods/room-pricing-periods.component';
import { RoomPricingSidebarComponent } from '../rooms/room-pricing-periods/room-pricing-sidebar/room-pricing-sidebar.component';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
 
import { LocationsService } from '../locations/locations-service';
 import { PromosService } from 'app/services/promos.service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from 'app/moment-utc-date-adapter';
import { RoomPricesListComponent } from '../rooms/room-prices-list/room-prices-list.component';
import { RoomPricesFormComponent } from '../rooms/room-prices-list/room-prices-form/room-prices-form.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HotelsService } from '../hotels/hotels-list/hotels-service';
import { ListPartenaireComponent } from './list-partenaire/list-partenaire.component';
import { B2BService } from './b2b.service';
import { ProCostomerFormComponent } from './pro-costomer-form/pro-costomer-form.component';
import { ReservationPartenaireComponent } from './reservation-partenaire/reservation-partenaire.component';
import { B2BBookingsService } from './b2bBooking.service';
 

const routes: Routes = [

    {
        path: 'list-partenaire',
        component: ListPartenaireComponent,
        resolve: {
            data: B2BService
        }
    },
    {
        path: 'réservations',
        component: ReservationPartenaireComponent,
        resolve: {
            data: B2BBookingsService
        }
        
    },
   
];

@NgModule({
    declarations: [
        ListPartenaireComponent,
        ProCostomerFormComponent,
        ReservationPartenaireComponent
    ],
    imports: [ 
         RouterModule.forChild(routes),
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        NgxChartsModule,
        AmazingTimePickerModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseSidebarModule,
        FuseConfirmDialogModule,
        MaterialModule,
        NgxDropzoneModule,
        CKEditorModule,
        
        SharedModule

    ],
    providers: [
        B2BService,
        B2BBookingsService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-CA' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {provide: LOCALE_ID, useValue: "fr-CA" },

    ],
    entryComponents: [
        ProCostomerFormComponent
    ]
})
export class B2BModule {
}
