import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-pro-costomer-form',
  templateUrl: './pro-costomer-form.component.html',
  styleUrls: ['./pro-costomer-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProCostomerFormComponent   {

  proForm: FormGroup;
  dialogTitle: string;

  proStatuses = [{ id: true, name: "Activé" }, { id: false, name: "Désactivé" }];
  action: string
  pro= {
    firstName: "",
    lastName: "",
    phone: "",
    email:"",
    business_name: "",
    identifier: "",
    password: "",
    status: false,
    
  }

   
  constructor(
    public matDialogRef: MatDialogRef<ProCostomerFormComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,

  ) {

    this.dialogTitle = 'Ajouter nouveau Partenaire';

    this.action = _data.action;

    if (this.action == "edit"){
      this.pro= _data.pro;
      this.dialogTitle = 'Modifier Partenaire';
    }

    
    console.log(this.pro)
    this.proForm = this.createProForm();


  }



  //createHotelPromoForm
  createProForm(): FormGroup {
    return this._formBuilder.group({
      firstName: [this.pro.firstName, [Validators.required]],
      lastName: [this.pro.lastName, [Validators.required]],
      email: [this.pro.email, [Validators.required]],
      phone: [this.pro.phone, [Validators.required]],
      password: [''],
      status: [this.pro.status, [Validators.required]],
      business_name: [this.pro.business_name, [Validators.required]],
      identifier:[this.pro.identifier, [Validators.required]],
      
    });
  }



  /*changeCheckFormula(formula) {
    if (!this.promo.formulas.includes(formula))
      this.promo.formulas.push(formula)
    else {
      const index = this.promo.formulas.indexOf(formula);
      if (index > -1) {
        this.promo.formulas.splice(index, 1);
      }
    }
  }*/

  



}
