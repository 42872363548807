import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'app/services/data.service';
import { Http } from '@angular/http';
import { Globals } from 'app/global/global';

@Injectable()
export class SettingsService extends DataService {

  routeParams: any;
  onSettingsChanged: BehaviorSubject<any>;

  constructor(http: Http) {

    super(Globals.ServerPoint, 'api/settings', http);

    this.onSettingsChanged = new BehaviorSubject({});

  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {

        Promise.all([
        ]).then(
            () => {
                resolve();
            },
            reject
        );
    });
}


fnOnSettingsChanged(data){
  this.onSettingsChanged.next(data);
}

}
