import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BookingsService } from '../bookings-service';
import { BookingHotelProductComponent } from './booking-hotel-product/booking-hotel-product.component';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Booking } from '../booking/booking.model';
import { DialogConfirm } from 'app/main/shared/dialog-confirm-component';

@Component({
  selector: 'traveldash-booking-products',
  templateUrl: './booking-products.component.html',
  styleUrls: ['./booking-products.component.scss']
})
export class BookingProductsComponent implements OnInit {


  @Input()
  booking: any;
  dialogRef: any;

  @Output()
  bookingChanged

  
  hotels:any[];

  // Private$
  private _unsubscribeAll: Subject<any>;
 
  adultsNumberRooms :number
  enfantNumberRooms:number
  constructor(
    private _bookingsService: BookingsService,
    private _matDialog: MatDialog,
    public dialog: MatDialog,
  ) {

    this.bookingChanged = new EventEmitter();

    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.getBookingDetails();
  }

  getBookingDetails() {
    this._bookingsService.setUrl("/single/" + this.booking._id);
    this._bookingsService.getAll()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.booking = res.result;
        this.booking.hotels.forEach(item => {
       item.rooms.forEach(element => {
         this.adultsNumberRooms=element.adultsDetails.length;
         this.enfantNumberRooms=element.childsDetails.length
         
       });
         
        });
        
        this.booking.hotels.reverse();
        this.bookingChanged.emit(res.result.totalPrice);
 
      });
  }

  deleteHotelProduct(hotel_id) {
    //Delete Hotel Product
    this._bookingsService.setUrl("/single/"+this.booking._id+"/hotel/delete")
    this._bookingsService.delete(hotel_id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.getBookingDetails();

      });

  }

  openNewHotel() {
    this.dialogRef = this._matDialog.open(BookingHotelProductComponent, {
      panelClass: 'booking-hotel-product-form-dialog',
      data: {
        action: 'new',
        bookingId: this.booking._id
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        if (response[0] == "SAVED") {
          this.getBookingDetails();
        }
        if (response[0] == "SAVE") {
          //this.createRoom(response[1].getRawValue());
        }
      });
  }

  openEditHotel(hotelDetails) {
    this.dialogRef = this._matDialog.open(BookingHotelProductComponent, {
      panelClass: 'booking-hotel-product-form-dialog',
      data: {
        action: 'edit',
        bookingId: this.booking._id,
        hotel: hotelDetails
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        if (response[0] == "SAVED") {
          this.getBookingDetails();
        }
        if (response[0] == "SAVE") {
          //this.createRoom(response[1].getRawValue());
        }
      });
  }


  openDialogForDeleteHotelProduct(id) {
    const dialogRef = this.dialog.open(DialogConfirm, {
      data: {
        confirm: true,
        dialogTitle: "Suppression Produit Hôtel",
        dialogMessage: "Voulez vous vraiment supprimer ce produit ?",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteHotelProduct(id);

      }
    });
  }


  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

  }


}
