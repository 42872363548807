import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {
  }


  canActivate(route, state: RouterStateSnapshot) {
    console.log(this.auth.currentAdmin.accountType);
    
if (this.auth.isLoggedFn() && (this.auth.currentAdmin.accountType == "Admin")) {
      return true;
    }


    this.router.navigate(['/auth/login']);
    return false;

  }


}
