import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDropzoneImagePreviewComponent } from './custom-dropzone-image-preview/custom-dropzone-image-preview.component';

@NgModule({
    imports: [
        CommonModule,
        
      ],
    declarations: [
        CustomDropzoneImagePreviewComponent,
        
    ],
    exports: [
        CustomDropzoneImagePreviewComponent,
     ]
})
export class SharedModule {}
