import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { FacilitiesService } from './facilities-service';


const routes = [

];

@NgModule({
    declarations: [

    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        
    ],
    exports: [
    ],
    providers: [
        FacilitiesService,
    ]
})

export class FacilitiesModule {
}
