import { Component, Inject, ViewEncapsulation, OnInit, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { HotelsService } from 'app/main/hotels/hotels-list/hotels-service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { p } from '@angular/core/src/render3';
import { BookingsService } from '../../bookings-service';
import * as moment from 'moment';

@Component({
  selector: 'app-booking-hotel-product',
  templateUrl: './booking-hotel-product.component.html',
  styleUrls: ['./booking-hotel-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class BookingHotelProductComponent implements OnInit, OnDestroy, AfterContentChecked {




  // Private
  private _unsubscribeAll: Subject<any>;

  action: string;
  bookingId: string;
  hotel: any;

  hotelProductForm: FormGroup;
  roomsForm: FormGroup;
  dialogTitle: string;

  hotels: any[] = [];
  rooms: any[] = [];
  roomPrices: any[] = [];
  roomExtras: any[] = [];
  filteredHotels: Observable<any[]>;
  selectedHotel: any = null;
  selectedRooms: any[] = [];
  subTotalPrice: number = 0
  subExtrasPrice: number = 0
  totalPrice: number = 0

  posI: number = 0
  posP: number = 0
  posL: number = 0

  validCheck: boolean = false;

  constructor(
    private cdref: ChangeDetectorRef,
    public matDialogRef: MatDialogRef<BookingHotelProductComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _fB: FormBuilder,
    private _hotelsService: HotelsService,
    private _bookingsService: BookingsService,

  ) {

    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.bookingId = _data.bookingId;
    this.action = _data.action;



  }
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }


  ngOnInit() {


    if (this.action == 'new') {
      this.dialogTitle = 'Réservation: Ajouter un Hôtel';
    } else {
      this.dialogTitle = 'Réservation: Modifier un Hôtel';
    }
    this.hotelProductForm = this.createHotelProductForm();

    this.buildRoomsForm();
    this.getHotelsList();

  }



  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.hotels.filter(hotel => hotel.name.toLowerCase().includes(filterValue));
  }


  get _roomDetails() {
    return (<FormArray>this.roomsForm.get('roomDetails')).controls
  }

  get _roomPrices() {
    return (<FormArray>(<FormArray>this.roomsForm.get('roomDetails')).controls[this.posI].get("roomPrices")).controls
  }

  get _roomLines() {
    return (<FormArray>(<FormArray>(<FormArray>this.roomsForm.get('roomDetails')).controls[this.posI].get("roomPrices")).controls[this.posP].get("roomLines")).controls
  }


  onChangeFormula(optionVal, i, p, index, _id, nbNigths) {

    const findFormula = this.roomPrices.find(formula => {
      return formula._id === _id && formula.name === optionVal
    })


    this.posI = i;
    this.posP = p;
    this.posL = index;


    this._roomLines[this.posL].get("selectedPrice").setValue(findFormula.price)
    this._roomLines[this.posL].get("linePrice").setValue(findFormula.price * nbNigths)

    this.setTotalPrice();

  }

  onChangeNights(nbNigths, i, p) {
    this.posI = i;
    this.posP = p;


    for (let it = 0; it < this._roomLines.length; it++) {
      this._roomLines[it].get("linePrice").setValue(nbNigths * this._roomLines[it].get("selectedPrice").value)
    }

    this.setTotalPrice();
  }


  setTotalPrice() {
    this.totalPrice = 0;

    for (let i = 0; i < this._roomDetails.length; i++) {
      this.posI = i;
      for (let j = 0; j < this._roomPrices.length; j++) {
        this.posP = j;
        for (let k = 0; k < this._roomLines.length; k++) {
          this.posL = k;

          this.totalPrice += this._roomLines[this.posL].get("linePrice").value;


        }
      }
    }

  }



  //getHotelsList
  getHotelsList() {
    this._hotelsService.setUrl("/list/minimum");
    this._hotelsService.getAll()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res.result) {
          this.hotels = res.result
          this.filteredHotels = this.hotelProductForm.get("hotelName").valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );

          if (this.action == 'edit') {
            this.totalPrice = this._data.hotel.hotelPrice;

            this.hotel = this._data.hotel;
            this.selectHotel(this.hotel.hotel.name);
            this.hotelProductForm.get("hotelName").setValue(this.hotel.hotel.name);
            this.hotelProductForm.get("checkin").setValue(this.hotel.checkin);
            this.hotelProductForm.get("checkout").setValue(this.hotel.checkout);
            this.hotelProductForm.get("nigths").setValue(this.hotel.nigths);
            console.log(this.hotel.hotel.name);


            this.getRoomsByPeriod();

          }
        }
      })
  }


  /*********************** */






  selectHotel(val) {
    this.selectedHotel = this.hotels.find((hotel) => {
      return hotel.name === val;
    });
  }

  changeQte(optionVal, index) {
    if (optionVal >= 0)
      for (let i = 0; i < this.roomPrices.length; i++) {
        if (this.roomPrices[i].id === index)
          this.roomPrices[i].qte = optionVal;
      }

    this.bindTotalPrice();
  }

  changeExtraQte(optionVal, parent, index) {

    if (optionVal >= 0)
      for (let i = 0; i < this.roomExtras.length; i++) {
        if (this.roomExtras[i].id === index && this.roomExtras[i].parent === parent)
          this.roomExtras[i].qte = optionVal;
      }

    this.bindTotalPrice();
  }

  selectFormula(optionVal, index) {

    for (let i = 0; i < this.roomPrices.length; i++) {
      if (this.roomPrices[i].id === index)
        this.roomPrices[i].selected = (this.roomPrices[i].name === optionVal);
    }


    this.bindTotalPrice();
  }


  setNightsPeriod(e) {
    const _checkin = this.hotelProductForm.get("checkin").value
    const _checkout = this.hotelProductForm.get("checkout").value
    if (_checkin != "" && _checkout != "") {
      var start = moment(_checkin, "YYYY-MM-DD");
      var end = moment(_checkout, "YYYY-MM-DD");
      this.hotelProductForm.get("nigths").setValue(Math.abs(moment.duration(start.diff(end)).asDays()));

      if (this.selectedHotel != null)
        this.validCheck = true;

      this.setNbNights();
    } else
      this.validCheck = false;

  }

  setNbNights() {

    this.totalPrice = this.subTotalPrice * this.hotelProductForm.get("nigths").value;

  }

  bindTotalPrice() {
    this.subTotalPrice = 0;
    this.subExtrasPrice = 0;
    this.roomPrices.forEach(element => {
      if (element.selected == true) {
        const p: number = parseFloat("" + element.price) * parseFloat("" + element.qte);
        this.subTotalPrice += p;
      }
    });

    this.roomExtras.forEach(element => {

      if (element.qte > 0) {
        const p: number = parseFloat("" + element.price) * parseFloat("" + element.qte);
        this.subExtrasPrice += p;
      }
    });


    this.totalPrice = (this.subTotalPrice + this.subExtrasPrice) * this.hotelProductForm.get("nigths").value;
  }

  trackByFn(index, item) {
    return index;
  }
  ttrackByFn(index, item) {
    return index;
  }
  ttttrackByFn(index, item) {
    return index;
  }





  //getRoomsByPeriod
  /* getRoomsByPeriod() {
     let hform = this.hotelProductForm.getRawValue();
     this._hotelsService.setUrl("/rooms/period/" + this.selectedHotel._id);
     this._hotelsService.create({ dateFrom: hform.checkin, dateTo: hform.checkout })
       .pipe(takeUntil(this._unsubscribeAll))
       .subscribe(res => {
         if (res.result) {
           this.rooms = res.result.rooms;
           res.result.rooms.roomPrices.forEach(element => {
             this.periods.push({
               _id: element._id,
               periodFrom: element.periodFrom,
               periodTo: element.periodTo,
             })
           });
 
         }
       });
   }*/

  //getRoomsByPeriod
  getRoomsByPeriod() {
    this.roomPrices = [];
    this.roomExtras = [];

    let hform = this.hotelProductForm.getRawValue();
    this._hotelsService.setUrl("/rooms/period/" + this.selectedHotel._id);
    this._hotelsService.create({ dateFrom: hform.checkin, dateTo: hform.checkout })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        if (res.result) {
          this.rooms = res.result.rooms;
console.log(this.rooms);


          let arr = [], index = 0;
          for (let i = 0; i < this.rooms.length; i++) {

            let priceObjects = [], room_extras = [], findRoom = null;

            //Edit : find room
            if (this.action == 'edit') {
              findRoom = this.hotel.rooms.find((find_room) => {
                return find_room.room === this.rooms[i]._id;
              });
              console.log("findRoom")
              console.log(findRoom)

            }



            this.rooms[i].roomPrices.forEach(element => {
              let room_prices = [], line_prices = [], nigths = 0;
              let priceObject;

              let obj = element.prices

              let _o;

              if (obj.housing.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "housing", name: "Logement Simple", price: obj.housing.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.breakfast.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "breakfast", name: "Petit-Déjeuner", price: obj.breakfast.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.halfBoard.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "halfBoard", name: "Demi-Pension", price: obj.halfBoard.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.halfBoardPlus.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "halfBoardPlus", name: "Demi-Pension +", price: obj.halfBoardPlus.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.fullBoard.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "fullBoard", name: "Pension-Complète", price: obj.fullBoard.price, qte: 0, selected: true }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.fullBoardPlus.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "fullBoardPlus", name: "Pension-Complète +", price: obj.fullBoardPlus.price, qte: 0, selected: true }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.allInclusive.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "allInclusive", name: "All-Inclusive", price: obj.allInclusive.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              
              if (obj.ultraAllInclusive.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "ultraAllInclusive", name: "Ultra All-Inclusive", price: obj.ultraAllInclusive.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.allInSoft.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "allInSoft", name: "All-In-Soft", price: obj.allInSoft.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }

              if (obj.ultraAllInSoft.status == true) {

                _o = { eid: element._id, _id: obj._id, id: index, type: "ultraAllInSoft", name: "Ultra All-In-Soft", price: obj.ultraAllInSoft.price, qte: 0, selected: false }

                room_prices.push(_o)
                this.roomPrices.push(_o)
              }


              if (findRoom != null && findRoom != undefined) {
                const findRoomPrices = findRoom.roomPrices.find((find_prices) => {
                  return find_prices.eid === element._id && find_prices._id === obj._id;
                });

                if (findRoomPrices != null && findRoomPrices != undefined) {
                  nigths = findRoomPrices.nigths;
                  findRoomPrices.roomLines.forEach(rline => {
                    line_prices.push({
                      adults: rline.adults,
                      children: rline.children,
                      formula: rline.formula,
                      subPrice: rline.subPrice,
                      unitPrice: rline.unitPrice,
                      roomExtras: rline.roomExtras
                    })
                  });
                }

              }


              priceObject = {
                eid: element._id,
                _id: obj._id,
                periodFrom: element.periodFrom,
                periodTo: element.periodTo,
                formulas: room_prices,
                nigths: nigths,
                linePrices: line_prices,
              }

              priceObjects.push(priceObject);

            });


            this.rooms[i].roomPrices = priceObjects;
            this.rooms[i].roomExtras = room_extras;

            arr.push(this.BuildFormDynamic(this.rooms[i]))
            index++;
          }




          this.roomsForm = this._formBuilder.group({
            roomDetails: this._fB.array(arr)
          })


          //this.roomsForm.updateValueAndValidity()

        }
      })
  }



  addRoomLine(i, p) {

    (<FormArray>(<FormArray>(<FormArray>this.roomsForm.get('roomDetails')).controls[i].get("roomPrices")).controls[p].get("roomLines")).controls.push(this._fB.group({
      adults: [0, Validators.min(0)],
      children: [0, Validators.min(0)],
      formula: [''],
      linePrice: [0],
      selectedPrice: [0],
    }));

  }

  removeRoomLine(i, p, l) {
    if ((<FormArray>(<FormArray>(<FormArray>this.roomsForm.get('roomDetails')).controls[i].get("roomPrices")).controls[p].get("roomLines")).controls.length > 0){
      (<FormArray>(<FormArray>(<FormArray>this.roomsForm.get('roomDetails')).controls[i].get("roomPrices")).controls[p].get("roomLines")).controls.splice(l, 1);
      this.setTotalPrice();
    }
  }

  buildRoomsForm() {
    let arr = [];
    this.roomsForm = this._formBuilder.group({
      roomDetails: this._fB.array(arr)
    })
  }

  BuildFormDynamic(room): FormGroup {
    let arr_ex = [];
    for (let i = 0; i < room.roomExtras.length; i++) {
      arr_ex.push(this._fB.group({
        _id: room.roomExtras[i]._id,
        extra: room.roomExtras[i].extra,
        price: room.roomExtras[i].price,
        quantite: [room.roomExtras[i].qte, Validators.min(0)],
      }))
    }

    let arr_prc = [];
    for (let i = 0; i < room.roomPrices.length; i++) {
      let arr_lines = this._fB.array([]);
      for (let p = 0; p < room.roomPrices[i].linePrices.length; p++) {
        arr_lines.push(
          this._fB.group({
            adults: [room.roomPrices[i].linePrices[p].adults, Validators.min(0)],
            children: [room.roomPrices[i].linePrices[p].children, Validators.min(0)],
            formula: [room.roomPrices[i].linePrices[p].formula],
            selectedPrice: [room.roomPrices[i].linePrices[p].unitPrice],
            linePrice: [room.roomPrices[i].linePrices[p].subPrice],
          })
        );
      }
      if (arr_lines.length == 0)
        arr_lines = this._fB.array([
          this._fB.group({
            adults: [0, Validators.min(0)],
            children: [0, Validators.min(0)],
            formula: [''],
            selectedPrice: [0],
            linePrice: [0],
          })
        ])
      arr_prc.push(this._fB.group({
        _id: [room.roomPrices[i]._id],
        eid: [room.roomPrices[i].eid],
        periodFrom: [room.roomPrices[i].periodFrom],
        periodTo: [room.roomPrices[i].periodTo],
        formulas: [room.roomPrices[i].formulas],
        nigths: [room.roomPrices[i].nigths],
        roomLines: arr_lines
      }))
    }

    /*const findPrice = room.roomPrices[0].prices.find((find_price) => {
      return find_price.selected === true && find_price.qte > 0;
    });
 
    let qte = 0, formula = '';
    if (findPrice) {
      qte = findPrice.qte;
      formula = findPrice.name;
    }*/

    return this._fB.group({
      _id: [room._id],
      roomType: [room.roomType],
      roomExtras: this._fB.array(arr_ex),
      roomPrices: this._fB.array(arr_prc),
      extra: [''],
    })
  }

  //createHotelProductForm
  createHotelProductForm(): FormGroup {
    return this._formBuilder.group({
      hotelName: ["", [Validators.required]],
      checkin: ['', [Validators.required]],
      checkout: ['', [Validators.required]],
      nigths: [1, [Validators.required, Validators.min(1)]],
    });
  }



  submitBookingHotel() {
    let hotel_form = this.hotelProductForm.getRawValue();

    const roomDetails = this.roomsForm.getRawValue();


    console.log(roomDetails)
    let rooms = []; let adults = 0, children = 0, nigths = 0;
    roomDetails.roomDetails.forEach(room => {


      let prices_obj = []; let quantite = 0;
      room.roomPrices.forEach(roomPrice => {


        if (roomPrice.nigths > 0) {
          let lines_obj = []
          roomPrice.roomLines.forEach(roomLine => {

            if (roomLine.linePrice > 0) {

              quantite++;
              adults += roomLine.adults;
              children += roomLine.children;

              lines_obj.push({
                adults: roomLine.adults,
                children: roomLine.children,
                formula: roomLine.formula,
                subPrice: roomLine.linePrice,
                unitPrice: roomLine.selectedPrice,
              });

            }
          });

          if (lines_obj.length > 0) {

            nigths += roomPrice.nigths;

            prices_obj.push({
              eid: roomPrice.eid,
              _id: roomPrice._id,
              periodFrom: roomPrice.periodFrom,
              periodTo: roomPrice.periodTo,
              nigths: roomPrice.nigths,
              roomLines: lines_obj,
            })
          }
        }
      });

      if (prices_obj.length > 0)
        rooms.push({
          _id: room._id,
          roomPrices: prices_obj,
          roomType: room.roomType,
          quantite: quantite,
        })


      console.log(prices_obj)

    });

    let submitData;

    if (this.action == 'edit') {
      submitData = {
        hotelId: this.selectedHotel._id,
        bhid: this.hotel._id,
        bid: this.bookingId,
        checkin: new Date(hotel_form.checkin),
        checkout: new Date(hotel_form.checkout),
        adults: adults,
        children: children,
        nigths: nigths,
        hotelPrice: this.totalPrice,
        rooms: rooms,
      }
      this._bookingsService.setUrl("/update/hotel");
      this._bookingsService.update(submitData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => {
          if (res.status == true)
            this.matDialogRef.close(['SAVED', null])
        });
    } else {
      submitData = {
        hotelId: this.selectedHotel._id,
        bid: this.bookingId,
        checkin: hotel_form.checkin,
        checkout: hotel_form.checkout,
        adults: adults,
        children: children,
        nigths: nigths,
        hotelPrice: this.totalPrice,
        rooms: rooms,
      }
      this._bookingsService.setUrl("/creation/hotel");
      this._bookingsService.create(submitData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(res => {
          if (res.status == true)
            this.matDialogRef.close(['SAVED', null])
        });

    }

  }
}
