export const bookingStatuses = [
    {
        'id'   : 1,
        'name' : 'Préparation de la commande',
        'color': 'orange-500'
    },
    {
        'id'   : 2,
        'name' : 'Pré-Commande (non payé)',
        'color': 'purple-300'
    },
    {
        'id'   : 3,
        'name' : 'En attente de paiement(en VB)',
        'color': 'blue-500'
    },
    {
        'id'   : 4,
        'name' : 'En attente de paiement(en espèces)',
        'color': 'blue-500'
    },
    {
        'id'   : 5,
        'name' : 'Erreur de paiement',
        'color': 'red-900'
    },
    {
        'id'   : 6,
        'name' : 'Commande validée (avance)',
        'color': 'green-500'
    },
    {
        'id'   : 7,
        'name' : 'Commande Validée (payée)',
        'color': 'green-800'
    },
    {
        'id'   : 8,
        'name' : 'Annulée',
        'color': 'red-500'
    },
    {
        'id'   : 9,
        'name' : 'Remboursée',
        'color': 'pink-500'
    },



];
