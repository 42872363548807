import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    static ServerPoint: string = 'https://api.forsavoyages.com/';
     _ServerPoint: string = 'https://api.forsavoyages.com/';
  static getServerPoint: string;


     get getServerPoint(){
         return 'https://api.forsavoyages.com/'
     }
 }
