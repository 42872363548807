import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { PaymentsService } from './payments-service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from 'app/moment-utc-date-adapter';


const routes = [

];

@NgModule({
    declarations: [

    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        
    ],
    exports: [
    ],
    providers: [
        PaymentsService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-CA' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {provide: LOCALE_ID, useValue: "fr-CA" },

    ]
})

export class PaymentsModule {
}
