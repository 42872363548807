import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Http } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'app/services/data.service';
import { Globals } from 'app/global/global';

@Injectable()
export class B2BService extends DataService implements Resolve<any>
{
     

    partenaires: any[];
    onPartenairesChanged: BehaviorSubject<any>;
    

    /**
     * Constructor
     *
     * @param {Http} _httpClient
     */
    constructor(
        private _httpClient: Http
    ) {
        super(Globals.ServerPoint, 'api/pro-customer', _httpClient);
        //super(Globals.ServerPoint, 'api/bookings', _httpClient);
        // Set the defaults
        this.onPartenairesChanged = new BehaviorSubject({});
      
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        

        return new Promise((resolve, reject) => {
                Promise.all([
                    this.getPartenaires()
                ]).then(
                    () => {
                        resolve();
                    },
                    reject
                );

        });
    }

    /**
     * Get partenaires
     *
     * @returns {Promise<any>}
     */
    getPartenaires(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.setUrl("")
            this.getAll()
                .subscribe((response: any) => {
                    this.partenaires = response.result;
                    console.log(this.partenaires);
                    
                    this.onPartenairesChanged.next(this.partenaires);
                    resolve(response.result);
                }, reject);
        });
    }
 
}
